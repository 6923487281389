import { useState } from "react";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { keycloakService } from "../../services/keycloakService";

// Define the GraphQL mutation for importing contacts
const IMPORT_CONTACTS_MUTATION = gql`
  mutation ImportContacts($contactList: [ContactDTOInput!]!) {
    importContacts(contactList: $contactList)
  }
`;

// Define TypeScript interfaces
export interface ContactDTOInput {
  contactId: string;
  name: string;
  type: string;
  taxCountry: string;
  juridical: string;
  status: string;
  classification: string;
  identity: string;
}

interface ImportContactsVariables {
  contactList: ContactDTOInput[];
}

interface ImportContactsResponse {
  importContacts: boolean;
}

export function useUpdateUserInfo() {
  const [importContactsMutation, { loading, error }] = useMutation<
    ImportContactsResponse,
    ImportContactsVariables
  >(IMPORT_CONTACTS_MUTATION, {
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });

  const updateUserInfo = async (
    contacts: ContactDTOInput[]
  ): Promise<boolean> => {
    try {
      const token = "";
      const result = await importContactsMutation({
        variables: {
          contactList: contacts,
        },
        context: {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      });
      console.log("Mutation result:", result);
      return result.data?.importContacts ?? false;
    } catch (err: unknown) {
      console.error("Error importing contacts:", err);
      if (err instanceof ApolloError) {
        console.error("GraphQL errors:", err.graphQLErrors);
        console.error("Network error:", err.networkError);
      }
      throw err;
    }
  };

  return {
    updateUserInfo,
    loading,
    error,
  };
}
