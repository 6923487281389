/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { LoadingIndicator } from "components";
import "./styles.css";
import { ApiError } from "./components/ApiError";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingForm } from "./components/OnboardingForm";
import { OnboardingHeader } from "./components/OnboardingHeader";
import { WelcomeInfo } from "./components/WelcomeInfo";
import { ProcessStatus } from "./types";
import { useKycUpdateProcess } from "./useKycUpdateProcess";
import { useGetUserInfo } from "../../api/user/useGetUserInfo";
import { useUpdateUserInfo } from "../../api/user/useUpdateUserInfo";

export const KycUpdateFormView = () => {
  const [processState, setProcessState] = useState<any>(null);

  const {
    loading: userInfoLoading,
    error: userInfoError,
    data: userInfo,
  } = useGetUserInfo();

  const {
    updateUserInfo,
    loading: updateLoading,
    error: updateError,
  } = useUpdateUserInfo();

  useEffect(() => {
    fetch("/processStateKyc.json")
      .then((response) => response.json())
      .then((data) => setProcessState(data))
      .catch((error) =>
        console.error("Error loading processState.json:", error)
      );
  }, []);

  if (!processState || userInfoLoading) {
    return <LoadingIndicator center />;
  }

  if (userInfoError) {
    console.error("Error fetching user info:", userInfoError);
    return <ApiError resetApiError={() => window.location.reload()} />;
  }

  const updatedFormOptions = processState
    ? {
        ...processState.formOptions,
        language: "FI",
      }
    : null;

  const { status, formDefinition, formOptions, initialFormData } = processState;

  console.log("userInfo", JSON.stringify(userInfo));

  const ready = status === ProcessStatus.READY && formDefinition && formOptions;

  const showLoading =
    status === ProcessStatus.LOADING || status === ProcessStatus.SUBMITTING;

  // Parse profileDataString
  const profileData = userInfo?.profileDataString
    ? Object.fromEntries(
        userInfo.profileDataString.split(",\n").map((item) => {
          const [key, value] = item.split("=");
          return [key.trim(), value.trim()];
        })
      )
    : {};

  // Use the userInfo data to populate the updatedInitialFormData
  const updatedInitialFormData = {
    userProfile: {
      email: userInfo?.addresses?.[0]?.email || "",
      email_verified: true,
      family_name: userInfo?.name?.split(" ").slice(-1)[0] || "",
      given_name: userInfo?.name?.split(" ").slice(0, -1).join(" ") || "",
      linked_contact: userInfo?.externalId || "",
      name: userInfo?.name || "",
      sub: userInfo?.profile?.id?.toString() || "",
      phone_number: userInfo?.addresses?.[0]?.phone1 || "",
      address: {
        street_address: userInfo?.addresses?.[0]?.address1 || "",
        locality: userInfo?.addresses?.[0]?.city || "",
        postal_code: userInfo?.addresses?.[0]?.zipCode || "",
        country: userInfo?.addresses?.[0]?.country || "",
      },
      birthdate: profileData["contact.trapets.dateofbirth"] || "",
      nationality: userInfo?.nationality?.code || "",
      tax_country: userInfo?.taxCountry?.code || "",
    },

    userGroups: userInfo?.tags
      ? (Array.isArray(userInfo.tags)
          ? userInfo.tags.join(", ")
          : userInfo.tags
        ).replace(/,\s*$/, "")
      : "",
    classification: userInfo?.classification?.code || "",
    juridical: userInfo?.juridical?.code || "",
    createdDate: userInfo?.createdDate || "",
    status: userInfo?.status || "",
    profileData: {
      ...profileData,
      fatcaStatus: profileData["contact.muuttiedot.fatcayksityishenkilo"] || "",
      riskProfile: profileData["contact.profiling.risk"] || "",
      investmentHorizon: profileData["contact.profiling.horizon"] || "",
      investmentFrequency: profileData["contact.profiling.frequency"] || "",
      averageInvestment:
        profileData["contact.profiling.averageInvestment"] || "",
      totalWealth: profileData["contact.profiling.kokonaisvarallisuus"] || "",
      experience: profileData["contact.profiling.years"] || "",
      cryptoexperience: profileData["contact.profiling.kokemuskryptot"] || "",
      cryptoexperienceinyears:
        profileData["contact.profiling.kokemuskryptotvuosia"] || "",
      originOfAssets: profileData["contact.companyDetails.origin"] || "",

      palkkatulo: profileData["contact.profiling.palkkatulo"] !== "false",
      yritystulo: profileData["contact.profiling.yritystulo"] !== "false",
      saastot: profileData["contact.profiling.saastot"] !== "false",
      perintolahja: profileData["contact.profiling.perintolahja"] !== "false",
      sijoitustuotot:
        profileData["contact.profiling.sijoitustuotot"] !== "false",
      asuntokiinteisto:
        profileData["contact.profiling.asuntokiinteisto"] !== "false",
      yritysmyynti: profileData["contact.profiling.yritysmyynti"] !== "false",
      muumyyntitulo: profileData["contact.profiling.muumyyntitulo"] !== "false",
      vakuutusmaksu: profileData["contact.profiling.vakuutusmaksu"] !== "false",
      avustukset: profileData["contact.profiling.avustukset"] !== "false",
      stocks: profileData["contact.profiling.stocks"] !== "false",
      bonds: profileData["contact.profiling.bonds"] !== "false",
      funds: profileData["contact.profiling.funds"] !== "false",
      etf: profileData["contact.profiling.etf"] !== "false",
      derivatives: profileData["contact.profiling.derivatives"] !== "false",
      structured: profileData["contact.profiling.structured"] !== "false",
      cryptocurrencies:
        profileData["contact.profiling.cryptocurrencies"] !== "false",
      other: profileData["contact.profiling.other"] !== "false",
    },
  };

  console.log("updatedInitialFormData", JSON.stringify(updatedInitialFormData));

  console.log("fatcaStatus", updatedInitialFormData.profileData.fatcaStatus);

  const handleSubmit = async (formData: any) => {
    // Prepare the profileDataString
    let profileDataString = "";
    if (formData.profileData && typeof formData.profileData === "object") {
      const profileDataEntries = Object.entries(formData.profileData);
      profileDataString = profileDataEntries
        .map(([key, value]) => `${key}=${value}`)
        .join(",\n");
    }

    try {
      // Then, prepare the data for the GraphQL mutation
      // const updateData = {
      //   id: userInfo?.id, // Changed from contactId to id
      //   externalId: formData.userProfile?.linked_contact,
      //   name: formData.userProfile?.name,
      //   addresses: [
      //     {
      //       address1: formData.userProfile?.address?.street_address,
      //       city: formData.userProfile?.address?.locality,
      //       country: formData.userProfile?.address?.country,
      //       email: formData.userProfile?.email,
      //       phone1: formData.userProfile?.phone_number,
      //       zipCode: formData.userProfile?.address?.postal_code,
      //     },
      //   ],
      // };
      // Send the update to the server
      const result = await updateUserInfo([
        {
          contactId: "173",
          name: "Onni Samuli Koronen",
          type: "1",
          taxCountry: "FI",
          juridical: "PE",
          status: "A",
          classification: "PROF",
          identity: "ID",
        },
      ]);

      if (result) {
        console.log("User info updated successfully");
        // Handle successful update (e.g., show a success message)
      } else {
        console.error("Failed to update user info:", result);
        // Handle update failure (e.g., show an error message)
      }
    } catch (error) {
      console.error("Error updating user info:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <>
      {ready && (
        <div className="bg-white">
          <OnboardingHeader />
          <div className="flex justify-center w-full">
            <div className="flex flex-wrap justify-center pt-3 lg:pt-10 w-full lg:max-w-[1400px]">
              <div className="lg:sticky lg:top-2 px-4 pt-2 mb-8 w-full lg:w-2/5 h-fit">
                <WelcomeInfo />
              </div>
              <div className="w-full lg:w-3/5">
                <OnboardingForm
                  initialFormData={updatedInitialFormData}
                  formOptions={updatedFormOptions}
                  formDefinition={formDefinition}
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
          <OnboardingFooter />
        </div>
      )}
      {(showLoading || updateLoading) && (
        <div className="h-screen">
          <LoadingIndicator center />
        </div>
      )}
      {(status === ProcessStatus.ERROR || userInfoError || updateError) && (
        <ApiError resetApiError={() => window.location.reload()} />
      )}
    </>
  );
};
