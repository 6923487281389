import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const OnboardingFooter = () => {
  const { t } = useModifiedTranslation();
  return (
    <footer className="flex gap-6 justify-center py-1 w-full text-xs text-gray-600 border-t-2 bottom">
      <a
        className=""
        target="_blank"
        href="https://www.kvarnx.com/palvelun-kayttoehdot"
        rel="noreferrer"
      >
        {t("onboardingPage.welcomeInfo.termsOfService")}
      </a>
      <a
        className=""
        target="_blank"
        href="https://www.kvarnx.com/tietosuojaseloste"
        rel="noreferrer"
      >
        {t("onboardingPage.welcomeInfo.privacyPolicy")}
      </a>
    </footer>
  );
};
